import React from "react"
import { Link } from "gatsby"
import PropTypes from "prop-types"
import CategoryImage from "./CategoryImage"
import { USD } from "../../helpers/NumberHelper"
import Carousel from "../Carousel"

export const SimilarProducts = ({ recommendedProducts }) => {
  const productsCorrectOrder = recommendedProducts.edges.reverse()
  return (
    <Carousel
      slides={{ xs: 1, lg: 2, xl: 3 }}
      gap={4}
      infiniteLoop={false}
      className={""}
      scrollStep={3}
    >
      {productsCorrectOrder.map((product, i) => {
        const { sku, name, url_key, title, small_image, thumbnail, model, brand, price } =
          product.node
        const url = "/" + url_key

        return (
          <div
            key={product.sku + "-" + i}
            className={
              "grid grid-rows-[auto_1fr_auto] text-center justify-start items-start gap-2 bg-white p-4 shadow-lg group"
            }
          >
            <div
              className={
                "translate-0 h-full transform px-4 duration-200 group-hover:-translate-y-2"
              }
            >
              <CategoryImage
                sku={sku}
                alt={name}
                title={title}
                normal={small_image}
                thumb={thumbnail}
                url={url}
              />
            </div>
            {(brand || "").length > 0 && (model || "").length > 0 ? (
              <>
                <h2 className={"text-center font-bold"}>{brand}</h2>
                <p>{model}</p>
              </>
            ) : (
              <>
                <h2 className={"text-center font-normal text-base line-clamp-3"}>{title}</h2>
              </>
            )}

            {/* <div className={'text-md text-center'}>{USD(price)}</div> */}
            <Link
              to={url}
              rel={"noreferrer"}
              className={"mx-auto w-auto bg-accent px-4 py-2 text-white hover:bg-link"}
            >
              See Details
            </Link>
          </div>
        )
      })}
    </Carousel>
  )
}

SimilarProducts.propTypes = {
  recommendedProducts: PropTypes.arrayOf(PropTypes.object).isRequired,
}

SimilarProducts.defaultProps = {
  recommendedProducts: null,
}
